const DOMAIN = 'cobranded.services.userSettings.privateArea.cbChallengeModule.smsChallenge.';
const DOMAIN_CM = 'cobranded.services.userSettings.privateArea.cbChallengeModule.';
const DOMAIN_PI =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.';

export default {
  BUTTON: `${DOMAIN}sendButton`,
  DESCRIPTION: `${DOMAIN}description`,
  ERROR_MESSAGE: `${DOMAIN_CM}error.description`,
  HELPER: `${DOMAIN}requestNewCodeDescription`,
  INPUT_ERROR: `${DOMAIN}cbInput.errorDescription`,
  PLACEHOLDER: `${DOMAIN}inputOtpPlaceholder`,
  REQUEST_LINK: `${DOMAIN}requestNewCodeLink`,
  REQUEST_NEW_CODE: `${DOMAIN}cbMessage.requestNewCode`,
  SUCCESS_MESSAGE: `${DOMAIN}cbMessage.successDescription`,
  TIMER_LABEL_SECOND: `${DOMAIN_PI}modifyEmail.step2.cbChallengeModule.timerMessageSecondLabel`,
  TIMER_LABEL_SECONDS: `${DOMAIN_CM}otpChallenge.timerMessageSecondsLabel`,
  TITLE: `${DOMAIN}title`,
  TRY_AGAIN: `${DOMAIN_PI}modifyPhoneNumber.errorFeedback.retryButton`,
};
