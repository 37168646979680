// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ProfilePersonalNameTitleContextConsumerHook } from 'containers/personal/views/landing/components/name/components/title/contexts/profile-personal-name-title.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import ProfilePersonalNameTitleDropdownHandlers from '../handlers/profile-personal-name-title-dropdown.handlers';
import ProfilePersonalNameTitleDropdownTrackingHandlers from '../handlers/profile-personal-name-title-dropdown.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameTitleInputHookType } from './types/profile-personal-name-title-dropdown.hook.type';
import { ProfilePersonalNameTitleDropdownApiMiscellaneousItemType } from '../types/profile-personal-name-title-dropdown-api-miscellaneous-item.type';

const ProfilePersonalNameTitleInputHook = (): ProfilePersonalNameTitleInputHookType => {
  const { profile } = AppContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook(),
    { setMe } = ManagerContextConsumerHook(),
    { setEditTitle } = ProfilePersonalNameTitleContextConsumerHook(),
    { title } = profile?.userDetails || {};

  const [fetching, setFetching] = useState<boolean>(false);
  const [titles, setTitles] = useState<ProfilePersonalNameTitleDropdownApiMiscellaneousItemType[]>(
    []
  );

  const { formatMessage } = useCbIntl();

  const { handleMiscellaneousTitlesDropdownClickTracking, handleMiscellaneousTitleClickTracking } =
      AppTrackingHook(ProfilePersonalNameTitleDropdownTrackingHandlers),
    { handleFetchMiscellaneousTitlesEvent, handleChangeCurrentTitleDropdownItemClickEvent } =
      ProfilePersonalNameTitleDropdownHandlers({
        handleMiscellaneousTitlesDropdownClickTracking,
        setFetching,
        setEditTitle,
        setMe,
        setToastConfiguration,
        setTitles,
      });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchMiscellaneousTitlesEvent();
  }, []);

  return {
    fetching,
    formatMessage,
    handleChangeCurrentTitleDropdownItemClickEvent,
    handleMiscellaneousTitleClickTracking,
    title,
    titles,
    isIosDevice: isIosWebView(),
  };
};

export default ProfilePersonalNameTitleInputHook;
