// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from './constants/personal-email-step2-actions.constants';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
// Utilities
import { modifyEmailStep2NextButtonIsDisabled } from './utils/personal-email-step2-actions.utils';

const PersonalEmailStep2ActionsComponent = (): React.ReactElement => {
  const { challenged, fetching, error } = PersonalEmailContextConsumerHook();

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={constants.WIZARD_PROPS.hasDefaultLeftButton}
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        disabled: modifyEmailStep2NextButtonIsDisabled({ challenged, error, fetching }),
        loading: fetching,
      }}
    />
  );
};

export default PersonalEmailStep2ActionsComponent;
