// Enumerations
import { ConsentTypesEnum } from 'containers/personal/views/consents/enumerations/consent-types.enum';

const DOMAIN = 'cobranded.services.userSettings.privateArea.consents.consentsLanding.step1.';

const HEADER_DESCRIPTIONS = {
  [ConsentTypesEnum.INTL]: `${DOMAIN}headerDescription1`,
  [ConsentTypesEnum.AMZN_WALLET]: `${DOMAIN}headerDescription2`,
};

const TOGGLE_DESCRIPTIONS = {
  [ConsentTypesEnum.INTL]: `${DOMAIN}toggleDescription1`,
  [ConsentTypesEnum.AMZN_WALLET]: `${DOMAIN}toggleDescription2`,
};

const STEP_DESCRIPTION1 = `${DOMAIN}description1`;

const STEP_DESCRIPTION2 = `${DOMAIN}description2`;

export { HEADER_DESCRIPTIONS, STEP_DESCRIPTION1, STEP_DESCRIPTION2, TOGGLE_DESCRIPTIONS };
