// Vendors
import React from 'react';
// Components
import ProfilePersonalNameTitleDropdownComponent from './components/dropdown/profile-personal-name-title-dropdown.component';
import ProfilePersonalNameTitleEditComponent from './components/edit/profile-personal-name-title-edit.component';
// Contexts
import {
  ProfilePersonalNameTitleContextConsumerHOC,
  ProfilePersonalNameTitleContextConsumerHook,
} from './contexts/profile-personal-name-title.context';

const ProfilePersonalNameTitleComponent = (): React.ReactElement => {
  const { editTitle } = ProfilePersonalNameTitleContextConsumerHook();
  return editTitle ? (
    <ProfilePersonalNameTitleDropdownComponent />
  ) : (
    <ProfilePersonalNameTitleEditComponent />
  );
};

export { ProfilePersonalNameTitleComponent };

export default ProfilePersonalNameTitleContextConsumerHOC(ProfilePersonalNameTitleComponent);
