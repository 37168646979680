// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from 'containers/personal/views/email/components/step3/components/actions/constants/personal-email-step3-actions.constants';
// Hooks
import PersonalEmailStep3ActionsHook from './hooks/personal-email-step3-actions.hook';
// Utilities
import { confirmChangeEmailActionButtonIsDisabled } from './utils/personal-email-step3-actions.utils';

const PersonalEmailStep3ActionsComponentCC = (): React.ReactElement => {
  const { challenged, fetching, handleConfirmChangeEmailButtonClick } =
    PersonalEmailStep3ActionsHook();

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={
        constants.PERSONAL_EMAIL_STEP_3_ACTIONS.WIZARD_PROPS.hasDefaultLeftButton
      }
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        onClick: handleConfirmChangeEmailButtonClick,
        disabled: confirmChangeEmailActionButtonIsDisabled({ challenged, fetching }),
        loading: fetching,
      }}
    />
  );
};

export default PersonalEmailStep3ActionsComponentCC;
