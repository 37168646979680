// Constants
import {
  CONSENT_CHECKBOXES,
  MAPPED_CONSENTS_TYPES,
} from './constants/profile-consents.utils.constants';
// Enumerations
import { ConsentTypesEnum } from '../enumerations/consent-types.enum';
import { ConsentsGivenEnum } from '../enumerations/consents-given.enum';
// Types
import { Consent } from '../types/consent.type';
import { ProfileConsentsConfig } from '../types/profile-consent.config.type';
import { ProfileSubConsent } from '../types/profile-consent.type';
import { ProfileConsentType } from '../types/profile-consent-type.type';

const mapResponseConsentsCallback = (apiConsent: Consent): ProfileSubConsent | undefined => {
  if (MAPPED_CONSENTS_TYPES.includes(apiConsent.consentType)) {
    return {
      ...CONSENT_CHECKBOXES[apiConsent.subTypes[0].consentSubType],
      id: apiConsent.consentType,
      value:
        apiConsent.consentGiven !== undefined
          ? apiConsent.consentGiven === ConsentsGivenEnum.YES
          : undefined,
    };
  }

  return undefined;
};

const joinConsentSubTypesCallback = (
  mappedConsents: Array<Array<ProfileSubConsent>>,
  currenConsent?: ProfileSubConsent
): Array<Array<ProfileSubConsent>> => {
  if (!currenConsent) {
    return [...mappedConsents];
  }

  if (currenConsent.title) {
    mappedConsents[mappedConsents.length - 1].push(currenConsent);
  } else {
    mappedConsents.push([currenConsent]);
  }

  return [...mappedConsents];
};

const mapToProfileConsentsConfigCallback = (
  consent: Array<ProfileSubConsent>
): ProfileConsentType => {
  const showConsents = consent.some(c => c.value);
  const areConsentsNotInformed = consent.some(c => c.value === undefined);

  return {
    subConsents: consent,
    id: consent[0].id.includes(ConsentTypesEnum.INTL) ? ConsentTypesEnum.INTL : consent[0].id,
    ...(areConsentsNotInformed ? {} : { showConsents }),
  };
};

const mapResponseConsentsToProfileConsents = (
  apiConsents: Consent[]
): ProfileConsentsConfig['consentTypes'] =>
  apiConsents
    .map(mapResponseConsentsCallback)
    .reduce(joinConsentSubTypesCallback, [[]])
    .map(mapToProfileConsentsConfigCallback);

export { mapResponseConsentsToProfileConsents };
