// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import ProfileConsentsSummaryComponent from './components/summary/profile-consents-summary.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import {
  CHALLENGE_WRAPPER_PROPS,
  WIZARD_FOOTER_PROPS,
} from './constants/profile-consents-step2.component.constants';
// Hooks
import useProfileConsentsStep2Hook from './hooks/profile-consents-step2.hook';
// Translations
import { CONFIRM_BUTTON } from './translations/profile-consents-step2.translations';
// Styles
import { ChallengeWrapperComponentStyled } from './profile-consents-step2.component.styled';
// Utilities
import { mapProfileConsentsToPatchConsents } from './utils/profile-consents-step2.utils';

const ProfileConsentsStep2Component = (): React.ReactElement | null => {
  const {
    challengeError,
    consents,
    challenged,
    fetching,
    formatMessage,
    handleChallengeError,
    handleChallengeSuccess,
    handleUpdateConsents,
    handleChallengeRetryButtonClick,
  } = useProfileConsentsStep2Hook();

  return consents ? (
    <>
      <ChallengeWrapperComponentStyled>
        <ProfileConsentsSummaryComponent {...{ consents: consents.consentTypes }} />
        <ChallengeWrapperComponent
          {...CHALLENGE_WRAPPER_PROPS}
          baseServiceBuilder={{
            pathParams: { arrangementId: consents.arrangementId },
            params: mapProfileConsentsToPatchConsents(consents),
          }}
          challengeError={challengeError}
          onError={handleChallengeError}
          onSuccess={handleChallengeSuccess}
          onRetry={handleChallengeRetryButtonClick}
        />
      </ChallengeWrapperComponentStyled>
      <WizardStepFooterComponent
        {...WIZARD_FOOTER_PROPS}
        isFooterDisabled={fetching}
        rightButtonConfiguration={{
          onClick: handleUpdateConsents,
          disabled: fetching || !challenged || challengeError,
          loading: fetching,
          children: formatMessage({ id: CONFIRM_BUTTON }),
        }}
      />
    </>
  ) : null;
};

export default ProfileConsentsStep2Component;
