// Constants
import {
  CUSTOMER_ROLE_T_CODE,
  CONSENT_SUB_TYPE_INTL_SEPATOR,
  DATE_LOCALE,
  DATE_FORMAT_OPTIONS,
} from './constants/profile-consents-step2.utils.constants';
// Enumerations
import { ConsentsGivenEnum } from 'containers/personal/views/consents/enumerations/consents-given.enum';
import { ConsentTypesEnum } from 'containers/personal/views/consents/enumerations/consent-types.enum';
// Types
import { ProfileConsentsConfig } from 'containers/personal/views/consents/types/profile-consent.config.type';
import { PatchConsentsType } from 'containers/personal/views/consents/types/patch-consents.type';
import { Consent } from 'containers/personal/views/consents/types/consent.type';
import { ProfileConsentType } from 'containers/personal/views/consents/types/profile-consent-type.type';
import { ProfileSubConsent } from 'containers/personal/views/consents/types/profile-consent.type';
import { SubConsentType } from 'containers/personal/views/consents/types/sub-consent.type';

const getSubTypes = (
  consent: ProfileConsentType,
  subConsent: ProfileSubConsent
): Array<SubConsentType> => [
  consent.id === ConsentTypesEnum.INTL
    ? {
        subTypeConsentGiven: subConsent.value ? ConsentsGivenEnum.YES : ConsentsGivenEnum.NO,
        consentSubType: subConsent.id.split(CONSENT_SUB_TYPE_INTL_SEPATOR)[1],
        consentStGivenDate: new Date().toLocaleDateString(DATE_LOCALE, DATE_FORMAT_OPTIONS),
      }
    : {
        consentSubType: ConsentTypesEnum.NO_CONSENT,
      },
];

const getConsentTypes = (consents: ProfileConsentsConfig['consentTypes']): Array<Consent> =>
  consents.flatMap(consent =>
    consent.subConsents.map(subConsent => ({
      consentGivenDate: new Date().toLocaleDateString(DATE_LOCALE, DATE_FORMAT_OPTIONS),
      consentType: subConsent.id,
      consentGiven: subConsent.value ? ConsentsGivenEnum.YES : ConsentsGivenEnum.NO,
      subTypes: getSubTypes(consent, subConsent),
    }))
  );

export const mapProfileConsentsToPatchConsents = (
  consents: ProfileConsentsConfig
): PatchConsentsType => ({
  body: {
    parties: [
      {
        customerId: consents.customerId,
        customerRoleTCode: CUSTOMER_ROLE_T_CODE,
      },
    ],
    cdpConsent: {
      consentTypes: getConsentTypes(consents.consentTypes),
    },
  },
});
