// Vendors
import React from 'react';
// Components
import { ChallengeEmailOtpComponent } from '@openbank/cb-ui-commons';
// Hooks
import PersonalEmailStep2ChallengeHook from './hooks/personal-email-step2-challenge.hook';
// Translations
import challengeTranslations from 'translations/challenge-email.translations';
import translations from './hooks/utils/translations/personal-email-step2-challenge.translations';
// Types
import { PersonalEmailStep2ChallengePropsType } from './types/personal-email-step2-challenge-props.type';
// Utils
import { formatTimerText } from 'utils/timer.utils';
import { translateLabels } from 'utils/translations.utils';

const PersonalEmailStep2ChallengeComponent = ({
  onRetry,
}: PersonalEmailStep2ChallengePropsType): React.ReactElement => {
  const {
    challenged,
    fetching,
    formatMessage,
    error,
    handleSendEmailOtpConfirmationButtonClick,
    handleRetryButtonClick,
    otpValue,
    setOtpValue,
    isTimerActive,
    timer,
    timerLabelTranslationId,
  } = PersonalEmailStep2ChallengeHook({ onRetry });

  return (
    <ChallengeEmailOtpComponent
      {...{ fetching, error, isTimerActive }}
      disabled={!challenged}
      handlers={{
        buttonHandler: handleSendEmailOtpConfirmationButtonClick,
        changeHandler: setOtpValue,
        requestHandler: handleRetryButtonClick,
        tryAgainHandler: onRetry,
      }}
      translations={{
        ...translateLabels({ formatMessage, translations: challengeTranslations }),
        REQUEST_NEW_CODE: formatTimerText({ formatMessage, timerLabelTranslationId, timer }),
        ERROR: {
          DESCRIPTION: formatMessage({ id: challengeTranslations.DESCRIPTION_ERROR }),
          BUTTON: formatMessage({ id: challengeTranslations.TRY_AGAIN }),
        },
      }}
      value={otpValue}
    />
  );
};

export default PersonalEmailStep2ChallengeComponent;
