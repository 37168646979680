// Resolvers
import { fetchProfileConsentsResolver } from '../resolvers/profile-consents.resolver';
// Utilities
import { mapResponseConsentsToProfileConsents } from '../utils/profile-consents.utils';
// Types
import { ProfileConsentsHandlersPropsType } from './types/profile-consents-props.handlers.type';
import { ProfileConsentsHandlersType } from './types/profile-consents.handlers.type';

const fetchProfileConsentsHandler = async ({
  setConsents,
  setError,
  setFetching,
  setInitialConsents,
}: ProfileConsentsHandlersPropsType): Promise<void> => {
  setFetching(true);

  const [consents, error] = await fetchProfileConsentsResolver();

  if (error) {
    setError(true);
  } else {
    const profileConsents = consents
      ? {
          ...consents,
          consentTypes: mapResponseConsentsToProfileConsents(consents.consentTypes),
        }
      : consents;

    setConsents(profileConsents);
    setInitialConsents(profileConsents);
  }

  setFetching(false);
};

const ProfileConsentsHandlers = ({
  setError,
  setConsents,
  setFetching,
  setInitialConsents,
}: ProfileConsentsHandlersPropsType): ProfileConsentsHandlersType => ({
  fetchProfileConsents: () =>
    fetchProfileConsentsHandler({
      setConsents,
      setError,
      setFetching,
      setInitialConsents,
    }),
});

export default ProfileConsentsHandlers;
