// Resolvers
import { changeUserAliasResolver } from '../resolvers/profile-personal-name-alias-input.resolvers';
// Translations
import translations from './translations/profile-personal-name-alias-input.handlers.translations';
// Types
import { ProfilePersonalNameAliasInputBuilderReturnHandlersType } from './types/profile-personal-name-alias-input-builder-return.handlers.type';
import { ProfilePersonalNameAliasInputBuilderHandlersType } from './types/profile-personal-name-alias-input-builder.handlers.type';
import { ProfilePersonalNameAliasInputSubmitBuilderHandlersType } from './types/profile-personal-name-alias-input-submit-builder.handlers.type';
// Utilities
import { isValidAlias } from '../utils/profile-personal-name-alias-input.utils';
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const submitLoginEmailInputEventHandler = async ({
  customerId,
  handleSubmitNewAliasInputEventTracking,
  newAlias,
  setEditMode,
  setFetching,
  setMe,
  setToastConfiguration,
}: ProfilePersonalNameAliasInputSubmitBuilderHandlersType): Promise<void> => {
  handleSubmitNewAliasInputEventTracking();
  setFetching(true);

  const [response, error] = await changeUserAliasResolver({ alias: newAlias, customerId });

  setFetching(false);
  setEditMode(false);
  response && setMe(true);
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations }) })
  );
};

const saveNewAliasInputEventHandler = ({
  newAlias,
  setNewAlias,
  setError,
}: Pick<
  ProfilePersonalNameAliasInputBuilderHandlersType,
  'newAlias' | 'setNewAlias' | 'setError'
>): any => {
  setNewAlias(newAlias);
  setError(false);
};

const checkNewAliasEmailInputEventHandler = ({
  newAlias,
  handleErrorStateChangeAliasInputEventTracking,
  setError,
}: Pick<
  ProfilePersonalNameAliasInputBuilderHandlersType,
  'newAlias' | 'handleErrorStateChangeAliasInputEventTracking' | 'setError'
>): any => {
  if (!isValidAlias(newAlias)) {
    setError(true);
    handleErrorStateChangeAliasInputEventTracking();
  }
};

const ProfilePersonalNameAliasInputHandlers = ({
  customerId,
  handleErrorStateChangeAliasInputEventTracking,
  handleSubmitNewAliasInputEventTracking,
  newAlias,
  setEditMode,
  setFetching,
  setError,
  setMe,
  setNewAlias,
  setToastConfiguration,
}: ProfilePersonalNameAliasInputBuilderHandlersType): ProfilePersonalNameAliasInputBuilderReturnHandlersType => ({
  handleCheckNewAliasEmailInputEvent: () =>
    checkNewAliasEmailInputEventHandler({
      newAlias,
      handleErrorStateChangeAliasInputEventTracking,
      setError,
    }),
  handleSaveNewAliasInputEvent: (newAlias: string) =>
    saveNewAliasInputEventHandler({ newAlias, setNewAlias, setError }),
  handleSubmitNewAliasInputEvent: () =>
    submitLoginEmailInputEventHandler({
      customerId,
      handleSubmitNewAliasInputEventTracking,
      newAlias,
      setEditMode,
      setFetching,
      setMe,
      setToastConfiguration,
    }),
});

export default ProfilePersonalNameAliasInputHandlers;
