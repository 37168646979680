const CONSENT_SUB_TYPE_INTL_SEPATOR = 'INTL.',
  CUSTOMER_ROLE_T_CODE = '',
  DATE_LOCALE: Intl.LocalesArgument = 'sv-SE',
  DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

export { CUSTOMER_ROLE_T_CODE, CONSENT_SUB_TYPE_INTL_SEPATOR, DATE_LOCALE, DATE_FORMAT_OPTIONS };
