// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Translations
import { PROFILE_CONSENTS_TITLES } from '../../translations/profile-consents.translations';

const CONSENT_CHECKBOXES = {
  APP: {
    title: PROFILE_CONSENTS_TITLES['APP'],
  },
  EMAIL: {
    title: PROFILE_CONSENTS_TITLES['EMAIL'],
  },
  PHONE: {
    title: PROFILE_CONSENTS_TITLES['PHONE'],
  },
  POSTAL: {
    title: PROFILE_CONSENTS_TITLES['POSTAL'],
  },
  SMS: {
    title: PROFILE_CONSENTS_TITLES['SMS'],
  },
  WEB: {
    title: PROFILE_CONSENTS_TITLES['WEB'],
  },
};

const MAPPED_CONSENTS_TYPES = [
  'AMZ.WALLET.R',
  'INTL.APP',
  'INTL.EMAIL',
  'INTL.WEB',
  'INTL.PHONE',
  'INTL.POSTAL',
  'INTL.SMS',
];
export { CONSENT_CHECKBOXES, MAPPED_CONSENTS_TYPES };
