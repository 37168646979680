// Translations
import translations from 'containers/personal/views/number/components/step2/components/challenge/hooks/utils/translations/personal-number-step2-challenge.translations';
// Constants
import { TIMER } from './constants/personal-number-step2-challenge.hook.utils.constants';

const getTimerLabelTranslationId = (timer: number): string => {
  return timer > TIMER ? translations.TIMER_LABEL_SECONDS : translations.TIMER_LABEL_SECOND;
};

export { getTimerLabelTranslationId };
