const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyEmail.step2.cbChallengeModule.';

export default {
  BUTTON: `${DOMAIN}sendButton`,
  DESCRIPTION: `${DOMAIN}description`,
  ERROR_MESSAGE: `${DOMAIN}cbMessage.errorDescription`,
  ERROR: `${DOMAIN}cbInput.errorDescription`,
  HELPER: `${DOMAIN}requestNewCodeDescription`,
  LINK: `${DOMAIN}requestNewCodeLink`,
  PLACEHOLDER: `${DOMAIN}inputOtpPlaceholder`,
  REQUEST_NEW_CODE: `${DOMAIN}cbMessage.requestNewCode`,
  SUCCESS_MESSAGE: `${DOMAIN}cbMessage.successDescription`,
  TIMER_LABEL_SECOND: `${DOMAIN}timerMessageSecondLabel`,
  TIMER_LABEL_SECONDS: `${DOMAIN}timerMessageSecondsLabel`,
  TITLE: `${DOMAIN}title`,
  TRY_AGAIN: `${DOMAIN}cbMessage.retryButton`,
};
